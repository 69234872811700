<!-- 招生系统基础配置 -- 中考普高控制线 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(()=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <el-form :model="form" :rules="rules" ref="form" label-width="150rem" style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="高考省份">
        {{ form.province }}
      </el-form-item>
      <el-form-item label="高考年份">
        {{ form.year }}
      </el-form-item>
      <el-form-item label="报名年份">
        {{ form.entry_year }}
      </el-form-item>
      <el-form-item label="报名学校">
        {{ form.school }}
      </el-form-item>
      <el-form-item label="第一档收费" prop="first">
        <el-input v-model="form.first" placeholder="请输入第一档收费" maxlength="10" @input="()=>{form.first = form.first.replace(/[^0-9.]/g, '')}"></el-input>
      </el-form-item>
      <el-form-item label="第二档收费" prop="second">
        <el-input v-model="form.second" placeholder="请输入第二档收费" maxlength="10" @input="()=>{form.second = form.second.replace(/[^0-9.]/g, '')}"></el-input>
      </el-form-item>
    </el-form>
    
  
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data(){
    return{
      backType: 0,
      form:{
        province: '',
        school_id: '',
        year: '',
        entry_year:'',
        first:'',
        second:'',
      },
      rules:{
        first: [{required:true,message:"请输入第一档收费"}],
        second: [{required:true,message:"请输入第二档收费"}],
      },
      provinceOptions: [],
      schoolOption: [],
    }
  },
  created() {
    this.$_register('/api/recruit/common/area',{params:{address_code: '000000'}}).then(res => {
      this.provinceOptions = res.data.data
    })
    this.$_register('/api/recruit/common/consulting-school').then(res => {
      this.schoolOption = res.data.data.entity_school
    })
    this.$_register.get('/api/recruit-v2/repeat-rank/show',{params:{id:this.$route.query.id}}).then(res=>{
      this.form = res.data.data
    })
  },

  methods:{
    // 提交数据
    submit(){
      this.$refs.form.validate((res)=>{
        if (res){
          let params = {...this.form}
          this.$_register.post("/api/recruit-v2/repeat-rank/update",params).then(res=>{
            this.$message({
              type: 'success',
              message: res.data.msg,
              onClose: () => {
                this.$router.back();
              }
            });
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.form{
  padding-top: 70rem;
}
::v-deep .el-form .el-input{
  min-width: 250rem;
}

.knowledge {
  padding-left: 60rem;
  margin-top: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    height: 60rem;
    background-color: #f4f4f4;
  }
  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}
</style>
